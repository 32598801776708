import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

export default function AboutComponent() {
  return (
    <section className="items-stretch grid gap-14 grid-cols-1 lg:grid-cols-2">
      <div className="">
        <div className="header-text-decorator mb-5">
          <h2 className="blue-dark-color text-xl lg:text-2xl font-bold ml-3">
            O NAS
          </h2>
        </div>
        <h3 className="text-lg text-justify mb-8 text-gray-600">
          Motoryzacja to nasza pasja od dzieciństwa. Firmę prowadzimy od 2013
          roku. Z roku na rok posiadamy coraz większe doświadczenie i grono
          zadowolonych klientów. Każdy z naszych samochodów indywidualnie
          sprawdzamy, odbywamy jazde próbną, mierzymy grubości lakieru oraz
          przeprowadzamy diagnostykę komputerową. Auta sprowadzamy w większosci
          z Niemiec od osób prywatnych. Poza handlem samochami zajmujemy się
          rownież <b>pomocą drogową</b> oraz{' '}
          <b>wypożyczalnią autolawet i lawet.</b>{' '}
        </h3>
        {/* zrobic przycisk na cala szerokosc na mobilki */}
        <div className="flex items-center w-full rounded-sm py-3 bg-yellow-500 hover:bg-yellow-400 text-white">
          <Link to="/about" className="w-full text-center">
            Więcej o nas.
          </Link>
        </div>
      </div>

      <StaticImage
        className="rounded-sm overflow-hidden"
        style={{ height: '650px' }}
        src="../assets/images/about_us.jpg"
        alt="M-Auto auto handel"
        placeholder="blurred"
      />
    </section>
  )
}
