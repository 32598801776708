import React from 'react'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import '../styles/index.scss'
import HeaderComponent from '../components/HeaderComponent'
import MenubarComponent from '../components/MenubarComponent'
import AboutComponent from '../components/AboutComponent'
import MoreComponent from '../components/MoreComponent'
import FooterComponent from '../components/FooterComponent'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

export default function Home({ data }) {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'pl',
        }}
      >
        <title>{data.site.siteMetadata.title}</title>
        <meta name="description" content={data.site.siteMetadata.description} />
        <meta name="keywords" content={data.site.siteMetadata.keywords} />
        <meta name="author" content={data.site.siteMetadata.author} />
        <meta property="og:title" content={data.site.siteMetadata.title} />
        <meta
          property="og:description"
          content={data.site.siteMetadata.description}
        />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:url" content={data.site.siteMetadata.url} />
        <link rel="canonical" href={data.site.siteMetadata.url} />
      </Helmet>

      <div className="overflow-hidden	flex">
        <MenubarComponent active="home" />
        <div className="carousel">
          {/* <Carousel> */}
          <HeaderComponent data={data} />
          {/* <HeaderComponent data={data} /> */}
          {/* </Carousel> */}
        </div>
      </div>

      <div className="mx-auto px-6 md:px-8 xl:px-16 max-w-screen-2xl flex-no-wrap flex gap-16 flex-col my-16">
        <AboutComponent />

        <div className="w-full h-0.5 bg-gray-100"></div>

        <div>
          <div className="header-text-decorator">
            <div className="ml-4">
              <h2 className="blue-dark-color text-2xl xl:text-3xl font-bold">
                Ogłoszenia
              </h2>
              <h3 className="text-gray-500 text-sm text-justify mt-1">
                Ostatnio dodane ogłoszenia
              </h3>
            </div>
          </div>
        </div>
        {data.allContentfulMauto.edges.length === 0 ? (
          <div className="text-center w-full">
            <h3 className="font-bold text-xl">
              Chwilowo brak ogłoszeń na naszej stronie
            </h3>
            <p className="text-gray-600 mt-2">Skontaktuj się z nami!</p>
          </div>
        ) : (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 md:gap-4 lg:gap-3 xl-gap-2">
              {data.allContentfulMauto.edges
                .reverse()
                .slice(0, 3)
                .map((car, id) => (
                  <Link to={`/${car.node.id}`} key={car.node.id}>
                    <div
                      className="shadow-md hover:bg-gray-50 rounded-sm overflow-hidden"
                      id={car.node.id}
                    >
                      <GatsbyImage
                        class="h-64 overflow-hidden"
                        image={getImage(car.node.zdjecia[0])}
                        alt="mauto oferta"
                      />

                      <div className="p-4 py-4">
                        <div className="flex flex-direction-row justify-between content-center">
                          <h2 className="text-3xl font-bold">
                            {car.node.marka}
                            <span className="text-gray-600 mx-2 text-lg">
                              /
                            </span>
                            <span className="text-gray-600 text-lg">
                              {car.node.model}
                            </span>
                          </h2>

                          <p className="text-yellow-600 text-base font-bold mt-2">
                            {car.node.cena}
                            <span className="font-normal"> PLN</span>
                          </p>
                        </div>
                        <p className="text-gray-500 text-sm mt-2 mb-4">
                          {car.node.moc} KM | {car.node.paliwo} |{' '}
                          {car.node.przebieg} km | {car.node.rok} |{' '}
                          {car.node.skrzynia}
                        </p>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
            <Link to="/cars">
              <div className="rounded-sm w-full xl:w-48 py-3 bg-yellow-500 hover:bg-yellow-400 text-center text-white">
                Wszystkie ogłoszenia
              </div>
            </Link>
          </>
        )}

        <div className="w-full h-0.5 bg-gray-100"></div>

        <MoreComponent />
      </div>
      <FooterComponent />
    </>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        url
        title
        keywords
        author
        description
      }
    }
    file(relativePath: { eq: "home_header.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }

    allContentfulMauto(
      filter: { contentful_id: { ne: "xiRXpu693Fo9h4x31HMV6" } }
      sort: { fields: createdAt }
    ) {
      edges {
        node {
          id
          marka
          model
          paliwo
          przebieg
          rok
          skrzynia
          cena
          moc
          tagi
          zdjecia {
            gatsbyImageData
          }
        }
      }
    }
  }
`
