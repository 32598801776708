import React from 'react'

export default function MoreComponent() {
  return (
    <section className="more">
      <div className="header-text-decorator mb-5">
        <div className="ml-4">
          <h2 className="blue-dark-color text-2xl xl:text-3xl font-bold">
            MAPA
          </h2>
          <h3 className="text-gray-500 font-light text-sm text-justify mt-1">
            Sprawdź jak do nas dojechać
          </h3>
        </div>
      </div>
      <div>
        <iframe
          title="mapa - jak dojechać do m-auto"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9993.945245420613!2d15.3768251!3d51.228538!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xed2b11048fab4dfc!2sM-AUTO%20S.C.!5e0!3m2!1spl!2sde!4v1641499533306!5m2!1spl!2sde"
          width="100%"
          height="400px"
          allowFullScreen
          loading="lazy"
        ></iframe>
      </div>
    </section>
  )
}
