import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

export default function HeaderComponent({ data }) {
  const image = getImage(data.file.childImageSharp.gatsbyImageData)
  return (
    <>
      <section className="header-section-container h-screen	w-screen relative">
        <div className="header-image-static absolute top-0 left-0 right-0 bottom-0 z-10">
          <GatsbyImage
            className="w-full h-full"
            image={image}
            alt="M-Auto Banner"
            objectFit="cover"
            objectPosition="50% 80%"
          />
        </div>

        <div className="header-text-container absolute flex z-30 h-full w-full ">
          <div className="flex mx-auto w-full px-8 md:px-16 lg:px-22 xl:px-16 2xl:px-8 max-w-screen-2xl justify-center md:justify-start items-center">
            {/* <div className="text-center w-full md:w-3/4 lg:w-1/2 xl:w/2/5 mx-auto"> */}
            <div className="text-center w-full sm:w-5/6 lg:w-3/5 xl:1/2 2xl:w-3/5 mx-auto">
              <h1 className="font-extrabold text-7xl md:text-8xl xl:text-9xl text-white tracking-tight	">
                M-AUTO
              </h1>
              <h2 className="font-light text-2xl md:text-2xl 2xl:text-3xl text-gray-300 tracking-tight mt-4 2xl:mt-2">
                Zadbane samochody używane z gwarancją w atrakcyjnych cenach.
                Import aut od prywatncyh osób z niemiec. Pomoc drogowa 24h/7.
              </h2>
              <Link to="/about">
                <button className="bg-yellow-500 hover:bg-yellow-400 rounded-sm text-white text-xl transition focus:outline-none px-20 py-3 mt-16">
                  O nas
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
